import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { Formik, Form, Field, FieldProps } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import classnames from "classnames";
import axios from 'axios';

import { PageLayout } from "../../layouts";
import { Block } from '../../components';

import { COUNTRIES, PLATFORM_OPTIONS, TEAM_CAPTAINS } from "../../global/js/helpers";

import "../../components/forms/forms.scss";
import "../../global/styles/page-styles/form-page.scss";

const API_ENDPOINT_SIGN_UP = process.env.API_ENDPOINT_SIGN_UP;

const MIN_AGE = 18;
const MAX_AGE = 99;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  custom_fields: Yup.object().shape({
    w7_T: Yup.string().required('Required').test(
      "DOB",
      `Must be at least ${MIN_AGE} years old and less than ${MAX_AGE} years old`,
      value => {
        return moment().diff(moment(value), 'years') >= MIN_AGE && moment().diff(moment(value), 'years') < MAX_AGE;
      }
    ),
    w3_T: Yup.string().required('Required'),
    w6_T: Yup.string().required('Required'),
    w8_T: Yup.string().required('Required'),
    w11_T: Yup.string().required('Required').test(
      'is-decimal',
      'Invalid K/D Ratio. Must be in 0.00 Format',
      value => (value + "").match(/^\d*\.{1}\d*$/)),
  }),
  email: Yup.string().required('Required').email('Please enter a valid email address'),
  phone_number: Yup.string().required('Required').matches(phoneRegExp, 'Phone number is not valid'),
  country: Yup.string().required('Required'),
});

const ContactPage = () => {
  const [formError, setFormError] = useState(undefined);
  const [successData, setSuccessData] = useState(undefined);

  /**
   * Store initial form state.
   * w2_T = team_captain
   * w3_T = username
   * w4_T = twitch
   * w5_T = twitter
   * w6_T = platform
   * w7_T = dob
   * w8_T = activision_id
   * w11_T = k/d_ratio
   */
  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    country: '',
    custom_fields: {
      w7_T: '',
      w8_T: '',
      w6_T: '',
      w2_T: '',
      w5_T: '',
      w4_T: '',
      w6_T: '',
      w3_T: '',
      w11_T: ''
    },
  }

  const handleSubmit = (values) => {
    
    axios.post(API_ENDPOINT_SIGN_UP, values)
      .then((res) => {
        if (res.data === 'Accepted') {
          // Update UI to show Success MSG.
          setSuccessData(values);
        }
        else if (res.data === false){
          setFormError(`Please subscribe to Partie Plus to enter.`)
        }
        else {
          setFormError(`There was an error. Please try again later.`)
        }
      })
      .catch(error => {
        // Update UI to show Error MSG.
        console.log('submit error: ', error);
        setFormError(`${error}. Please try again later.`)
      });
  };

  return (
    <>
      <Helmet>
        <title>Partie | Tournament Application</title>
      </Helmet>
      <PageLayout className="form-page" isLight>
        <Block form>
          <h2 className="partie-block__title">Call Of Duty®: Warzone™ <br />
            Community Clash — 9.24.21 <br />
            Tournament Application</h2>
          {successData ? (
            <div className="success-message">
              <p>Thank you,
                your application has been
                submitted for review.</p><p>
                We will contact you
                for additional information
                based on your eligibility.</p>
              <a className="btn btn--accent" href="https://app.partie.com">
                To Partie
              </a>
            </div>
          ) : (
            <div>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form className="partie-form" id="partie-form">
                  <Field name="first_name">
                    {({ field, meta }) => (
                      <div className={classnames("partie-form__field-container", {
                        'partie-form__field-container--error': meta.error && meta.touched
                      })}>
                        <label className="partie-form__label" htmlFor="first_name">
                          First Name *
                        </label>
                        <input
                          className="partie-form__field"
                          id="first_name"
                          type="text"
                          onChange={field.onChange}
                        />
                        {(meta.error && meta.touched) && (
                          <span className="partie-form__field-caption">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="last_name">
                    {({ field, meta }) => (
                      <div className={classnames("partie-form__field-container", {
                        'partie-form__field-container--error': meta.error && meta.touched
                      })}>
                        <label className="partie-form__label" htmlFor="last_name">
                          Last Name *
                        </label>
                        <input
                          className="partie-form__field"
                          id="last_name"
                          type="text"
                          onChange={field.onChange}
                        />
                        {(meta.error && meta.touched) && (
                          <span className="partie-form__field-caption">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="custom_fields.w7_T">
                    {({ field, meta }) => (
                      <div className={classnames("partie-form__field-container", {
                        'partie-form__field-container--error': meta.error && meta.touched
                      })}>
                        <label className="partie-form__label" htmlFor="custom_fields.w7_T">
                          DOB *
                        </label>
                        <input
                          className="partie-form__field"
                          id="custom_fields.w7_T"
                          type="date"
                          onChange={field.onChange}
                        />
                        {(meta.error && meta.touched) && (
                          <span className="partie-form__field-caption">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="email">
                    {({ field, meta }) => (
                      <div className={classnames("partie-form__field-container", {
                        'partie-form__field-container--error': meta.error && meta.touched
                      })}>
                        <label className="partie-form__label" htmlFor="email">
                          Email *
                        </label>
                        <input
                          className="partie-form__field"
                          id="email"
                          type="email"
                          onChange={field.onChange}
                        />
                        {(meta.error && meta.touched) && (
                          <span className="partie-form__field-caption">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="phone_number">
                    {({ field, meta }) => (
                      <div className={classnames("partie-form__field-container", {
                        'partie-form__field-container--error': meta.error && meta.touched
                      })}>
                        <label className="partie-form__label" htmlFor="phone_number">
                          Phone Number *
                        </label>
                        <input
                          className="partie-form__field"
                          id="phone_number"
                          type="tel"
                          onChange={field.onChange}
                        />
                        {(meta.error && meta.touched) && (
                          <span className="partie-form__field-caption">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="custom_fields.w2_T">
                    {({ form }) => (
                      <div className="partie-form__field-container">
                        <label
                          className="partie-form__label"
                          id="team_captain-label"
                          htmlFor="custom_fields.w2_T"
                        >
                          Select Preferred Team Captain
                        </label>
                        <CreatableSelect
                          aria-labelledby="team_captain-label"
                          classNamePrefix="partie-form__select"
                          inputId="custom_fields.w2_T"
                          options={TEAM_CAPTAINS}
                          onChange={(e) => {
                            form.setFieldValue("custom_fields.w2_T", e.value);
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 10,
                            colors: {
                              ...theme.colors,
                              primary: "#6e4aff",
                              primary25: "#6e4aff40",
                            },
                          })}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="custom_fields.w3_T">
                    {({ field, meta }) => (
                      <div className={classnames("partie-form__field-container", {
                        'partie-form__field-container--error': meta.error && meta.touched
                      })}>
                        <label className="partie-form__label" htmlFor="custom_fields.w3_T">
                          Partie Username *
                        </label>
                        <input
                          className="partie-form__field"
                          id="custom_fields.w3_T"
                          type="text"
                          onChange={field.onChange}
                        />
                        {(meta.error && meta.touched) && (
                          <span className="partie-form__field-caption">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="custom_fields.w8_T">
                    {({ field, meta }) => (
                      <div className={classnames("partie-form__field-container", {
                        'partie-form__field-container--error': meta.error && meta.touched
                      })}>
                        <label className="partie-form__label" htmlFor="custom_fields.w8_T">
                          Activision ID *
                        </label>
                        <input
                          className="partie-form__field"
                          id="custom_fields.w8_T"
                          type="text"
                          onChange={field.onChange}
                        />
                        {(meta.error && meta.touched) && (
                          <span className="partie-form__field-caption">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="custom_fields.w11_T">
                    {({ field, meta }) => (
                      <div className={classnames("partie-form__field-container", {
                        'partie-form__field-container--error': meta.error && meta.touched
                      })}>
                        <label className="partie-form__label" htmlFor="custom_fields.w11_T">
                          K/D Ratio *
                        </label>
                        <input
                          id="custom_fields.w11_T"
                          className="partie-form__field"
                          type="text"
                          onChange={field.onChange}
                        />
                        {(meta.error && meta.touched) && (
                          <span className="partie-form__field-caption">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="custom_fields.w6_T">
                    {({ form, meta }) => (
                      <div className={classnames("partie-form__field-container", {
                        'partie-form__field-container--error': meta.error && meta.touched
                      })}>
                        <label
                          className="partie-form__label"
                          id="platform-label"
                          htmlFor="custom_fields.w6_T"
                        >
                          Console or PC Options *
                        </label>
                        <Select
                          aria-labelledby="platform-label"
                          classNamePrefix="partie-form__select"
                          inputId="custom_fields.w6_T"
                          options={PLATFORM_OPTIONS}
                          onChange={(e) => {
                            form.setFieldValue("custom_fields.w6_T", e.value);
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 10,
                            colors: {
                              ...theme.colors,
                              primary: "#6e4aff",
                              primary25: "#6e4aff40",
                            },
                          })}
                        />
                        {(meta.error && meta.touched) && (
                          <span className="partie-form__field-caption">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="custom_fields.w5_T">
                    {({ field }) => (
                      <div className="partie-form__field-container">
                        <label className="partie-form__label" htmlFor="custom_fields.w5_T">
                          Twitter Handle
                        </label>
                        <input
                          id="custom_fields.w5_T"
                          className="partie-form__field"
                          type="text"
                          onChange={field.onChange}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="custom_fields.w4_T">
                    {({ field }) => (
                      <div className="partie-form__field-container">
                        <label className="partie-form__label" htmlFor="custom_fields.w4_T">
                          Twitch Profile
                        </label>
                        <input
                          id="custom_fields.w4_T"
                          className="partie-form__field"
                          type="text"
                          onChange={field.onChange}
                        />
                      </div>
                    )}
                  </Field>

                  <Field name="country">
                    {({ form, meta }) => (
                      <div className={classnames("partie-form__field-container", {
                        'partie-form__field-container--error': meta.error && meta.touched
                      })}>
                        <label
                          className="partie-form__label"
                          id="country-label"
                          htmlFor="country"
                        >
                          Country *
                        </label>
                        <Select
                          aria-labelledby="country-label"
                          classNamePrefix="partie-form__select"
                          inputId="country"
                          options={COUNTRIES}
                          onChange={(e) => {
                            form.setFieldValue("country", e.value);
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 10,
                            colors: {
                              ...theme.colors,
                              primary: "#6e4aff",
                              primary25: "#6e4aff40",
                            },
                          })}
                        />
                        {(meta.error && meta.touched) && (
                          <span className="partie-form__field-caption">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <div className="partie-form__field-container">
                    <button className="btn btn--accent" type="submit">
                      Submit
                    </button>
                    {formError && (
                      <span className="partie-form__field-caption">{formError}</span>
                    )}
                  </div>
                </Form>
              </Formik>
              <div className="sign-up-rules">
                <p>All entrants and participants must have a verified Partie
                  account and active Partie Plus Subscription.</p>
                <p>Players must be at least 18 years of age in order to compete.</p>
                <p>Tournament applicants will undergo a formal intake and screening
                  process to gather their information / KD / account history, gauge
                  eligibility and gain acceptance into their preferred captain’s
                  team or be assigned to an available team.
                </p>
                <p>Registration and/or active subscription does not guarantee entry.
                  Incomplete or duplicate registrations will not be considered.</p>
                <p>For complete event and tournament rules please visit <Link to="/clash/rules">partie.com/clash/rules</Link></p>
              </div>
            </div>
          )}
        </Block>
      </PageLayout>
    </>
  );
};

export default ContactPage;